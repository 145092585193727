<template>
  <div>
    <a-table :columns="columns" :data-source="valueList" :pagination="false">
      <span slot="action" slot-scope="text, record">
        <a @click.prevent="editUser(record)">编辑</a>
        <a-divider type="vertical" />
      </span>
    </a-table>
    <a-row :gutter="[16,16]" class="editor-info" style="margin-top:20px;">
      <a-col class="gutter-row" :xl="6" :lg="12" :md="12" style="text-align:right">
        <!-- @updateDes="updateDes" -->
        <Editor  v-model="info" style="height:495px;" />
        <a-button type="primary" style="margin-right:10px" @click="submitRule">保存</a-button> 
      </a-col>
      <a-col class="gutter-row" :xl="6" :lg="12" :md="12">
        <!--  -->
        <div class="phone-con" >
          <img src="@/assets/img/head.jpg" style="width:100%" />
          <div class="edit-content" v-html="info"></div>
        </div>
      </a-col>
      <a-col class="gutter-row" :xl="6" :lg="12" :md="12">
        <p>扫码约车二维码</p>
        <img :src="appointPic" alt="" class="pic">
        <a-button type="primary" @click="downloadImg(1)">下载</a-button>
      </a-col>
      <a-col class="gutter-row" :xl="6" :lg="12" :md="12">
        <p>上车扫码二维码</p>
        <img :src="getCarPic" alt="" class="pic">
        <a-button type="primary" @click="downloadImg(2)">下载</a-button>
      </a-col>
    </a-row>
    <!-- 参数编辑 -->
    <a-modal v-model="visible" title="参数设置" okText="确定" cancelText="取消" @ok="setValue">
      <div>
        {{val.itemName}}<br><br>
        <a-input v-model="val.itemValue"  />
      </div>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: '意义',
    dataIndex: 'itemName',
    key: 'itemName',
    width:300
  },
  {
    title: '参数',
    dataIndex: 'itemValue',
    key: 'itemValue',
    width:100
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
import Editor from "../components/Editor"
export default {
  components:{
    Editor
  },
  data() {
    return {
      visible:false,
      columns,
      valueList:[],
      edit:false,
      val:{},
      appointPic:'',
      getCarPic:'',
      info:''
    }
  },
  mounted(){
    this.getValueList()
    this.getAppoint()
    this.getGetCar()
    this.getRule()
  },
  methods: {
    submitRule(){
      let _this = this;
      this.$confirm({
        title: '是否确认保存?',
        content: '确认保存信息之后，小程序端预约规则同步更新',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          _this.saveRule()
        },
        onCancel() {
        },
      });
    },
    saveRule(){
      this.$post(this.$url.添加预约须知, {
        busInstructions:this.info
      }).then(data => {
        this.$tips('保存成功','信息保存成功，请在小程序端查看','success')
      }).catch(() => {
        
      });
    },
    getRule(){
      this.$post(this.$url.获取预约须知, {
      }).then(data => {
        this.info = data.data.busInstructions
      }).catch(() => {
        
      });
    },
    updateDes(info){
      this.info = info
    },
    getAppoint(){
      this.$post(this.$url.下载二维码, {
        companyId:this.$store.state.companyId
      }).then(data => {
        this.appointPic = "data:image/jpeg;base64,"+data.data.qrcode; 
      }).catch(() => {
        this.$tips('生成失败','请重新操作！','error')
      });
    },
    getGetCar(){
      this.$post(this.$url.上车二维码, {
        companyId:this.$store.state.companyId
      }).then(data => {
        this.getCarPic = "data:image/png;base64,"+data.data.qrcode; 
      }).catch(() => {
        this.$tips('生成失败','请重新操作！','error')
      });
    },
    downloadImg(i){
      let aLink = document.createElement('a');
      let blob = this.base64ToBlob(i==1?this.appointPic:this.getCarPic); 
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);
      aLink.download = i==1?"扫码约车":"扫码上车"+".jpg";
      aLink.href = URL.createObjectURL(blob);
      aLink.click()
    },
    base64ToBlob(code) {
        let parts = code.split(';base64,');
        let contentType = parts[0].split(':')[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
    },
    editUser(item){
      this.edit = true
      this.val = Object.assign({},item)
      this.visible = true
    },
    getValueList(){
      this.$post(this.$url.管理参数,{}).then(data => {
        this.valueList = data.data.list
      }).catch(() => {
          
      });
    },
    setValue(){
      this.$post(this.$url.参数设置, {
        itemId:this.val.itemId,
        itemValue:this.val.itemValue
      }).then(data => {
        this.$tips('设置成功','参数设置成功！','success')
        this.visible = false
        this.getValueList()
      }).catch(() => {
        this.$tips('设置失败','请重新设置参数！','error')
      });
    },
  },
}
</script>
<style scoped>
.gutter-row p{
  margin-bottom:15px;
}
.edit-content{
  padding-top:10px;
  text-align:left;
  background:#fff;
  height:388px;
  box-sizing: border-box;
  padding:15px 5px 5px;
  overflow-y:scroll
}
.phone-con{
  background:url('~@/assets/img/phone.png') center 0 no-repeat;
  background-size: auto 100%;
  height:530px;
  box-sizing: border-box;
  padding:45px 17px;
  width:270px;
  margin:0 auto;
}
.pic{
  width:100%;
  max-width:300px;
  height:auto;
  border:solid 1px #eee;
  display:block;
  margin:0 auto 20px;
  
}
.editor-info{
  text-align:center;
}
</style>